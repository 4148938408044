import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { apiClient } from '@/ApiClient';
import MoreMenuItem from '@/components/ReportTable/MoreMenuItem';
import { filterQueryParams } from '@/components/ReportTypesPanel/utils';
import SpriteIcon from '@/components/SpriteIcon/SpriteIcon';
import { objToQueryString } from '@/router/router';
import { AppDispatch, store } from '@/store';
import {
  EAdvancedPdfData,
  EFileTypes,
  EInventoryDataPage,
  EMarketDistributionData,
  EOrder,
  EReportName,
  EReportTypes,
  EReportUnit,
} from '@/store/enums';
import { getFilterLists } from '@/store/filterListsSlice';
import {
  getKeySettings,
  getSavedSearchList,
  resetFilters,
  setKeySettings,
} from '@/store/keySettingsSlice';
import {
  loadReport,
  setAvailableReports,
  setDisplayMap,
  setShortInfo,
} from '@/store/reportSlice';
import { Button, Menu, MenuItem } from '@mui/material';

import './ReportsMenu.scss';

export interface IMenu {
  agent: Array<IMenuItem>;
  office: Array<IMenuItem>;
  firm: Array<IMenuItem>;
  brand: Array<IMenuItem>;
  market_trends: Array<IMenuItem>;
  advanced_pdf: Array<IMenuItem>;
}

export interface IMenuItem {
  name: string;
  key: string;
  unit: string;
  child?: Array<IMenuItem>;
}

const getHref = (params: Record<string, string>): string => {
  const urlParams = new URLSearchParams(
    filterQueryParams(window.location.search)
  );

  for (const [key, value] of Object.entries(params)) {
    if (value === '') {
      urlParams.delete(key);
    } else {
      urlParams.set(key, value);
    }
  }

  const queryString = urlParams.toString();
  const url = `${window.location.origin}${window.location.pathname}`;

  return queryString ? `${url}?${queryString}` : url;
};

const paramsWithData = (
  data: string,
  reportName: string
): Record<string, string> => {
  return {
    agentIds: '',
    brandIds: '',
    officeIds: '',
    firmIds: '',
    reportName: reportName,
    orderDirection: 'desc',
    data: data,
  };
};

const params = (
  reportName: string,
  reportUnit: string
): Record<string, string> => {
  return {
    agentIds: '',
    brandIds: '',
    officeIds: '',
    firmIds: '',
    reportName,
    reportUnit,
    orderDirection: 'desc',
  };
};

function Element(props: { item: IMenuItem }) {
  const { item } = props;
  const dispatch = useDispatch<AppDispatch>();
  const { reportName } = useSelector(getKeySettings);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const handleOpen = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateKeySettingsToEmptyArray = (keys: string[]) => {
    const actions = keys.map((key) => setKeySettings({ key, value: [] }));
    actions.forEach((action) => dispatch(action));
  };

  const selectReport = async (key: string, unit: string) => {
    dispatch(setDisplayMap(false));
    dispatch(
      setKeySettings({
        key: 'reportType',
        value: EReportTypes.COMBINED,
      })
    );
    dispatch(
      setKeySettings({
        key: 'reportName',
        value: key,
      })
    );
    await dispatch(
      setKeySettings({
        key: 'orderBy',
        value: null,
      })
    );
    await dispatch(
      setKeySettings({
        key: 'timePeriod',
        value: null,
      })
    );
    updateKeySettingsToEmptyArray([
      'agentIds',
      'officeIds',
      'firmIds',
      'brandIds',
      'data',
    ]);
    await dispatch(
      setKeySettings({
        key: 'orderDirection',
        value: null,
      })
    );
    await dispatch(resetFilters(reportName));
    await dispatch(
      setKeySettings({
        key: 'page',
        value: 1,
      })
    );
    dispatch(
      setKeySettings({
        key: 'reportUnit',
        value: unit,
      })
    );
    dispatch(setShortInfo(null));
    dispatch(loadReport());
    dispatch(getSavedSearchList());
  };

  const handleSelectReport = (
    e: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    e.preventDefault();
    selectReport(item.key, item.unit);
  };

  if (item.child?.length) {
    return (
      <>
        <MenuItem
          className="report-menu-item"
          selected={!!anchorEl}
          onClick={handleOpen}
          onTouchStart={handleOpen}
        >
          <span style={{ paddingRight: 15 }}>{t(`reports.` + item.key)}</span>
          <SpriteIcon icon="arrow_down" />
        </MenuItem>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          className="w-100"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {item.child.map((item: IMenuItem, index: number) => (
            <Element key={index} item={item} />
          ))}
        </Menu>
      </>
    );
  } else {
    return (
      <a
        style={{ textDecoration: 'none', color: 'inherit' }}
        role="button"
        target="_blank"
        rel="noreferrer"
        href={getHref(params(item.key, item.unit))}
        onClick={handleSelectReport}
        onTouchStart={handleSelectReport}
      >
        <MenuItem
          className="report-menu-item"
          selected={item.key === reportName}
        >
          {t(`reports.` + item.key)}
        </MenuItem>
      </a>
    );
  }
}

const handlingDistribution = (
  dispatch: AppDispatch,
  reportUnit: string | EReportUnit,
  reportName: string | EReportName,
  mls: number,
  orderDirection: EOrder,
  lastDate: string | null,
  data: string,
  chartType: string,
  perPage: number,
  report: string
) => {
  const startDate = moment
    .utc(lastDate)
    .subtract(1, 'years')
    .add(1, 'months')
    .startOf('month')
    .format('YYYY-MM-DD');

  // dispatch(
  //   resetKeySettings({
  //     mls,
  //     timeFrame: ETimeFrames.YEAR,
  //     startDate,
  //     endDate: lastDate,
  //     reportUnit,
  //     reportName,
  //     orderDirection,
  //     chartType,
  //     perPage,
  //   })
  // );

  dispatch(resetFilters(reportName));

  dispatch(
    setKeySettings({
      key: 'reportName',
      value: report,
    })
  );
  dispatch(setKeySettings({ key: 'data', value: data }));

  dispatch(loadReport());
  dispatch(getSavedSearchList());
};

function MenuItemTop(props: { name: string; menuItem: Array<IMenuItem> }) {
  const { name, menuItem } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { labels, lastDate } = useSelector(getFilterLists);
  const dispatch = useDispatch();
  const { reportUnit, reportName, mls, orderDirection, chartType, perPage } =
    useSelector(getKeySettings);
  const handleClick = (
    event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMarketPerformanceDistribution = (
    e: any,
    data: string,
    report: string
  ) => {
    e.preventDefault();

    handlingDistribution(
      dispatch,
      reportUnit,
      reportName,
      mls,
      orderDirection,
      lastDate,
      data,
      chartType,
      perPage,
      report
    );
  };

  const handleMarketComparativeDistribution = (
    e: any,
    data: string,
    report: string
  ) => {
    e.preventDefault();

    handlingDistribution(
      dispatch,
      reportUnit,
      reportName,
      mls,
      orderDirection,
      lastDate,
      data,
      chartType,
      perPage,
      report
    );
  };

  const exportInventoryReport = (
    e: any,
    advancedPdfData: string,
    inventoryDataPage: number
  ) => {
    e.preventDefault();

    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const {
      mls,
      timeFrame,
      reportName,
      savedSearch,
      savedSearchList,
      reportType,
      ...fields
    } = store.getState().keySettings;

    if (!mls) return;

    const query =
      objToQueryString(fields) +
      `&data=${advancedPdfData}&dataPage=${inventoryDataPage}`;

    return apiClient
      .exportReport(mls, EReportName.INVENTORY, query, EFileTypes.TYPE_PDF)
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const exportMonthlyHousingStatisticsReport = (
    e: any,
    advancedPdfData: string
  ) => {
    e.preventDefault();

    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const {
      mls,
      timeFrame,
      reportName,
      savedSearch,
      savedSearchList,
      reportType,
      ...fields
    } = store.getState().keySettings;

    if (!mls) return;

    const query = objToQueryString(fields) + `&data=${advancedPdfData}`;

    return apiClient
      .exportReport(
        mls,
        EReportName.MONTHLY_HOUSING_STATISTICS,
        query,
        EFileTypes.TYPE_PDF
      )
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const exportMarketOverviewPriceRangeReport = (
    e: any,
    advancedPdfData: string
  ) => {
    e.preventDefault();

    toast.info(<Trans i18nKey="messages.few_moments" />);
    // eslint-disable-next-line
    const {
      mls,
      timeFrame,
      reportName,
      savedSearch,
      savedSearchList,
      reportType,
      ...fields
    } = store.getState().keySettings;

    if (!mls) return;

    const query = objToQueryString(fields) + `&data=${advancedPdfData}`;

    return apiClient
      .exportReport(
        mls,
        EReportName.MARKET_OVERVIEW_PRICE_RANGE,
        query,
        EFileTypes.TYPE_PDF
      )
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const filename = response.headers['content-disposition']
          .split('filename=')[1]
          .split('.')[0];
        const extension = response.headers['content-disposition']
          .split('.')[1]
          .split(';')[0];

        const link = url.createObjectURL(
          new Blob([response.data], {
            type: response.headers['content-type'],
          })
        );
        const a = document.createElement('a');
        a.download = `${filename}.${extension}`;
        a.href = link;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  return (
    <>
      <Button
        className="menu-btn"
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        {t(`report_categories.` + name)}{' '}
        {menuItem.length ? <SpriteIcon icon="arrow_down" /> : null}
      </Button>
      {menuItem.length ? (
        <Menu
          className="w-100"
          anchorEl={anchorEl}
          open={open}
          onClick={handleClose}
          onTouchStart={handleClose}
        >
          {menuItem.map((item, index) => {
            if (item.key === EReportName.MARKET_PERFORMANCE_DISTRIBUTION) {
              return (
                <MoreMenuItem
                  key={index}
                  style={{ color: 'inherit' }}
                  label={t(`reports.market-performance-distribution`)}
                  className="report-menu-item"
                >
                  {Object.values(EMarketDistributionData).map(
                    (distributionData) => {
                      const labelFromLabels = labels.find(
                        (item) => item.type === distributionData
                      )?.name;

                      return (
                        <a
                          key={distributionData}
                          href={getHref(
                            paramsWithData(
                              distributionData as string,
                              EReportName.MARKET_PERFORMANCE_DISTRIBUTION
                            )
                          )}
                          onClick={(e) => {
                            handleMarketPerformanceDistribution(
                              e,
                              distributionData,
                              EReportName.MARKET_PERFORMANCE_DISTRIBUTION
                            );
                          }}
                          onTouchStart={(e) => {
                            handleMarketPerformanceDistribution(
                              e,
                              distributionData,
                              EReportName.MARKET_PERFORMANCE_DISTRIBUTION
                            );
                          }}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            color: 'var(--reports-links)',
                            fontSize: '12px',
                          }}
                        >
                          <MenuItem
                            onClick={handleClose}
                            onTouchStart={handleClose}
                            style={{ fontSize: 'inherit' }}
                          >
                            {labelFromLabels
                              ? labelFromLabels
                              : t(`buttons.${distributionData}`)}
                          </MenuItem>
                        </a>
                      );
                    }
                  )}
                </MoreMenuItem>
              );
            } else if (
              item.key === EReportName.MARKET_COMPARATIVE_DISTRIBUTION
            ) {
              return (
                <MoreMenuItem
                  key={index}
                  style={{ color: 'inherit' }}
                  label={t(`reports.market-comparative-distribution`)}
                  className="report-menu-item"
                >
                  {Object.values(EMarketDistributionData).map(
                    (distributionData) => {
                      const labelFromLabels = labels.find(
                        (item) => item.type === distributionData
                      )?.name;

                      return (
                        <a
                          key={distributionData}
                          href={getHref(
                            paramsWithData(
                              distributionData as string,
                              EReportName.MARKET_COMPARATIVE_DISTRIBUTION
                            )
                          )}
                          target="_blank"
                          rel="noreferrer"
                          onClick={(e) => {
                            handleMarketComparativeDistribution(
                              e,
                              distributionData,
                              EReportName.MARKET_COMPARATIVE_DISTRIBUTION
                            );
                          }}
                          onTouchStart={(e) => {
                            handleMarketComparativeDistribution(
                              e,
                              distributionData,
                              EReportName.MARKET_COMPARATIVE_DISTRIBUTION
                            );
                          }}
                          style={{
                            color: 'var(--reports-links)',
                            fontSize: '12px',
                          }}
                        >
                          <MenuItem
                            onClick={handleClose}
                            onTouchStart={handleClose}
                            style={{ fontSize: 'inherit' }}
                          >
                            {labelFromLabels
                              ? labelFromLabels
                              : t(`buttons.${distributionData}`)}
                          </MenuItem>
                        </a>
                      );
                    }
                  )}
                </MoreMenuItem>
              );
            } else if (item.key === EReportName.INVENTORY) {
              return (
                <MoreMenuItem
                  key={index}
                  style={{ color: 'inherit' }}
                  label={t(`reports.inventory`)}
                  className="report-menu-item"
                >
                  {Object.values(EAdvancedPdfData).map((advancedPdfData) => {
                    const labelFromLabels = labels.find(
                      (item) => item.type === advancedPdfData
                    )?.name;

                    return (
                      <MoreMenuItem
                        key={advancedPdfData}
                        style={{ color: 'inherit' }}
                        label={
                          labelFromLabels
                            ? labelFromLabels
                            : t(`buttons.${advancedPdfData}`)
                        }
                        className="report-menu-item"
                      >
                        {Object.values(EInventoryDataPage).map(
                          (inventoryDataPage, index) => {
                            const innerLabelFromLabels = labels.find(
                              (item) => item.type === inventoryDataPage
                            )?.name;

                            return (
                              <a
                                key={inventoryDataPage}
                                href={getHref(
                                  paramsWithData(
                                    advancedPdfData as string,
                                    EReportName.INVENTORY
                                  )
                                )}
                                target="_blank"
                                rel="noreferrer"
                                onClick={(e) => {
                                  exportInventoryReport(
                                    e,
                                    advancedPdfData,
                                    index
                                  );
                                }}
                                onTouchStart={(e) => {
                                  exportInventoryReport(
                                    e,
                                    advancedPdfData,
                                    index
                                  );
                                }}
                                style={{
                                  color: 'var(--reports-links)',
                                  fontSize: '12px',
                                }}
                              >
                                <MenuItem
                                  onClick={handleClose}
                                  onTouchStart={handleClose}
                                  style={{ fontSize: 'inherit' }}
                                >
                                  {innerLabelFromLabels
                                    ? innerLabelFromLabels
                                    : t(`buttons.${inventoryDataPage}`)}
                                </MenuItem>
                              </a>
                            );
                          }
                        )}
                      </MoreMenuItem>
                    );
                  })}
                </MoreMenuItem>
              );
            } else if (item.key === EReportName.MONTHLY_HOUSING_STATISTICS) {
              return (
                <MoreMenuItem
                  key={index}
                  style={{ color: 'inherit' }}
                  label={t(`reports.monthly-housing-statistics`)}
                  className="report-menu-item"
                >
                  {Object.values(EAdvancedPdfData).map((advancedPdfData) => {
                    const labelFromLabels = labels.find(
                      (item) => item.type === advancedPdfData
                    )?.name;

                    return (
                      <a
                        key={advancedPdfData}
                        href={getHref(
                          paramsWithData(
                            advancedPdfData as string,
                            EReportName.MONTHLY_HOUSING_STATISTICS
                          )
                        )}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          exportMonthlyHousingStatisticsReport(
                            e,
                            advancedPdfData
                          );
                        }}
                        onTouchStart={(e) => {
                          exportMonthlyHousingStatisticsReport(
                            e,
                            advancedPdfData
                          );
                        }}
                        style={{
                          color: 'var(--reports-links)',
                          fontSize: '12px',
                        }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          onTouchStart={handleClose}
                          style={{ fontSize: 'inherit' }}
                        >
                          {labelFromLabels
                            ? labelFromLabels
                            : t(`buttons.${advancedPdfData}`)}
                        </MenuItem>
                      </a>
                    );
                  })}
                </MoreMenuItem>
              );
            } else if (item.key === EReportName.MARKET_OVERVIEW_PRICE_RANGE) {
              return (
                <MoreMenuItem
                  key={index}
                  style={{ color: 'inherit' }}
                  label={t(`reports.market-overview-price-range`)}
                  className="report-menu-item"
                >
                  {Object.values(EAdvancedPdfData).map((advancedPdfData) => {
                    const labelFromLabels = labels.find(
                      (item) => item.type === advancedPdfData
                    )?.name;

                    return (
                      <a
                        key={advancedPdfData}
                        href={getHref(
                          paramsWithData(
                            advancedPdfData as string,
                            EReportName.MARKET_OVERVIEW_PRICE_RANGE
                          )
                        )}
                        target="_blank"
                        rel="noreferrer"
                        onClick={(e) => {
                          exportMarketOverviewPriceRangeReport(
                            e,
                            advancedPdfData
                          );
                        }}
                        onTouchStart={(e) => {
                          exportMarketOverviewPriceRangeReport(
                            e,
                            advancedPdfData
                          );
                        }}
                        style={{
                          color: 'var(--reports-links)',
                          fontSize: '12px',
                        }}
                      >
                        <MenuItem
                          onClick={handleClose}
                          onTouchStart={handleClose}
                          style={{ fontSize: 'inherit' }}
                        >
                          {labelFromLabels
                            ? labelFromLabels
                            : t(`buttons.${advancedPdfData}`)}
                        </MenuItem>
                      </a>
                    );
                  })}
                </MoreMenuItem>
              );
            }

            return <Element key={index} item={item} />;
          })}
        </Menu>
      ) : null}
    </>
  );
}

const ReportsMenu = () => {
  const [menu, setMenu] = useState<IMenu>();
  const { mls } = useSelector(getKeySettings);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const loadMenu = () => {
      apiClient
        .availableReports(mls)
        .then((data) => {
          setMenu(data);
          dispatch(setAvailableReports(data));
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    };

    loadMenu();
  }, [mls]);

  const keys = menu
    ? Object.keys(menu).map((item: string) => item as keyof IMenu)
    : [];

  return (
    <>
      {menu && (
        <>
          {Object.entries(menu).map(([key, value], index) => {
            if (Array.isArray(value)) {
              const menuItem = value;
              return <MenuItemTop key={index} name={key} menuItem={menuItem} />;
            } else {
              const item = value;
              return <Element key={index} item={item} />;
            }
          })}
        </>
      )}
    </>
  );
};

export default ReportsMenu;
